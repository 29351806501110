var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{attrs:{"label":_vm.labelTitle,"label-for":_vm.name}},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":_vm.name,"rules":_vm.validate},scopedSlots:_vm._u([{key:"default",fn:function(ref$1){
var errors = ref$1.errors;
return [_c('vue-select',{ref:_vm.ref,class:[{ 'is-invalid': errors.length > 0 }],attrs:{"dir":_vm.dir,"multiple":_vm.multiple,"close-on-select":_vm.closeOnSelect_v,"clearable":_vm.clearable_v,"placeholder":_vm.placeholder,"name":_vm.name,"options":_vm.options,"label":_vm.label,"reduce":_vm.reduce,"disabled":_vm.disabled,"value":_vm.selected,"loading":_vm.showLoadingIcon,"taggable":_vm.taggable,"no-drop":_vm.taggable},on:{"input":_vm.onChange,"search:focus":_vm.onFocus,"search:blur":_vm.onBlur,"option:deselected":_vm.deselected,"option:selecting":_vm.selecting},scopedSlots:_vm._u([(!_vm.taggable)?{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('span',{staticClass:"icon",attrs:{"data-icon":"T"}})])]}}:null,(_vm.showSelectAll)?{key:"list-header",fn:function(){return [_c('li',{staticClass:"cursor-pointer text-white pl-3 bg-warning",on:{"click":function($event){_vm.checkAll = !_vm.checkAll; _vm.checkAllOptions()}}},[_vm._v("Select All")])]},proxy:true}:null,(_vm.noOptionsText)?{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" "+_vm._s(_vm.$t('basic.no_results_found_for'))+" "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(_vm._s(_vm.noOptionsText))])]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._t("default")],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }