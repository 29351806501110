export default {
  discountCode: 'كود الخصم',
  clientName: 'اسم العميل',
  country: 'الدولة',
  city: 'المدينة',
  street: 'الشارع',
  district: 'الحى',
  phoneNumber: 'رقم الجوال',
  addressDetails: 'تفاصيل العنوان او علامة مميزة',
  specialSign: 'علامة مميزة',
  cardNumber: 'رقم البطاقة',
  monthEnd: 'شهر الانتهاء',
  yearEnd: 'سنة الانتهاء',
  cvv: 'CVV',
  activate: 'تفعيل',
  diactivate: 'إلغاء',
  addNewCreditCard: 'اضف بطاقة إئتمانية جديدة',
  addNewCredit: 'اضف بطاقة إئتمانية',
  sure: 'تأكيد',
  paymentOptions: 'طرق الدفع',
  myAddress: 'عناوينك',
  addNewAddress: 'اضف عنوانا جديدا',
  addAddress: 'إضافة عنوان',
  chooseAddress: 'اختيار العنوان',
  validOrder: ' تأكيد الطلب',
  continueShopping: 'متابعه التسوق',
  orderInfo: 'ملخص الطلب',
  tax: 'ضريبة القيمة المضافة',
  transportation: 'الشحن',
  sum: 'المجموع',
  edit: 'تعديل',
  change: 'تغير'
}
