// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const search = () => import('./views/index')

// start Routes
export default [
  {
    path: '/search',
    name: 'search',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'search',
      meta: {
        name: 'search',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            // text: 'endUser.transport',
            active: true
          }
        ]
      },
      component: search
    }
    ]
  }
]
