/* import Api from '@/axios' */
import { marketPlace, website } from '@/axios'

export default {
/*  listDataTabl (url, filter) {
    return Api().get(url, {
      params: filter
    })
  },
  addImage (payload, config) {
    return Api().post('add-image', payload, config)
  },
  removeImage (id, type) {
    return Api().get(`remove-image/${id}/${type}`)
  },
  removeRow (url, rowId) {
    console.log(url, rowId)
    return Api().delete(`${url}/${rowId}`)
  },
  changeStatus (payload) {
    return Api().post('change-status', payload)
  } */
  getAllCities () {
    return marketPlace().get('cities')
  },
  getAllContries () {
    return marketPlace().get('countries')
  },
  getGlobalSearch (data) {
    return website().post('user/search', data)
  }
}
