export default {
  transportationDate: 'transportation date',
  transportationTime: 'transportation time',
  camelTransport: 'Camel transportation',
  HowTransport: 'How to transport?',
  HowTransportDesc: 'Through the IBBIL platform, you can choose the truck you want to transport your \n' +
    'camels with, its type and size, in addition to negotiating with the driver to choose \n' +
    'the suitable price for you, to ensure the best means of transporting camels at \n' +
    'anytime and anywhere in the Kingdom of Saudi Arabia.',
  startingPoint: 'starting point',
  startLocation: 'start location',
  endLocation: 'end location',
  destination: 'destination',
  tripDistance: 'Trip distance in Km',
  headerTitle: 'Transport camels whenever you want to anywhere',
  headerSubtitle: 'Now you can transport your camels within the Kingdom of Saudi Arabia',
  headerBtn: 'Transfer now',
  headerDetails: 'The IBBIL platform is considered one of the specialized and distinguished platforms in offering the services of transporting camels safely whenever you want and to any place, through the drivers and transportation companies that offer their transportation services on the IBBIL platform. Due to our belief in the importance of camels in relation to our heritage and our Saudi Arabian history, we had to create a platform that enables you to easily reach drivers and trucks to transport your camels. We are always keen to transport camels with the highest safety and the best possible price',
  transportDesc: 'After filling out the form, the price of the camel transport will appear to you. If you \n' +
'accept the price, you will be transferred to the payment system through one of the \n' +
'approved payment methods in Saudi Arabia. After that, an invoice will be issued for \n' +
'receiving the application. ',
  chooseFreelyTitle: 'choose freely',
  chooseFreelyDesc: 'All camel owners in the Kingdom of Saudi Arabia are totally free to choose the truck, its size, the preferred driver and the suitable price for them.',
  targetAudienceTitle: 'Transportation Target Audience',
  targetAudienceDesc: 'All camel owners in the Kingdom of Saudi Arabia can safely transport camels within the Kingdom of Saudi Arabia to any place by following the steps for transporting camels.',
  choosePriceTitle: 'Choose the right price for you',
  choosePriceDesc: 'When you choose the preferred truck, you have the absolute freedom to set the \n' +
    'suitable price for you by negotiating with the driver you have chosen to transport \n' +
    'your camels.',
  vehicleType: 'vehicle type',
  vehicleYear: 'vehicle year',
  vehicleNumber: 'vehicle number',
  acceptTrip: 'Your ride has been accepted by the driver',
  vehicleInfo: 'vehicle data',
  payWithin: 'Pay within 15 minutes until the order is confirmed',
  attention: 'attention',
  amountPaid: 'The amount to be paid',
  paymentCompleted: 'Payment completed successfully',
  thanks: 'Thank you.',
  downloadApp: 'Download our software from Google Play or the App Store to track your travels and enjoy all your services',
  shipmentData: 'Shipment data',
  applicant: 'applicant',
  totalValueTransport: 'The total value of the trip',
  trip: 'trip',
  trips: 'trips',
  cancel: 'cancel',
  youSureCancel: 'Are you sure you want to cancel the trip ?',
  deliveryData: 'Delivery data',
  chooseVehicleType: 'choose vehicle type',
  payment: 'payment',
  camel: 'camel',
  approximatePrice: 'Approximation price',
  selectTruck: 'You must select a truck type first',
  lookingDrivers: 'Looking for drivers for your trip',
  notifyDriver: 'You will be notified once a driver has accepted your ride',
  driverName: 'driver name',
  companyName: 'company name',
  driverOffer: 'driver offer',
  companyOffer: 'company offer',
  cancelReason: 'cancel reason',
  tripCancelIn: 'Trip canceled in',
  vehicleData: 'vehicle data',
  transferData: 'transfer data',
  name: 'name:',
  send: 'send',
  accept: 'accept',
  refuse: 'refuse',
  refuseTrip: 'refuse the trip',
  continue: 'continue',
  from: 'from',
  to: 'to',
  tripId: 'Trip ID',
  OrderNumber: 'Order number',
  customerData: 'customer data',
  distance: 'distance',
  time: 'time',
  camelNumber: 'camel number',
  DriverPhoto: 'Driver photo',
  numberOfTrips: 'The number of his trips',
  driverRating: 'driver rating',
  rs: 'SAR',
  comment: 'comment',
  addComment: 'add comment',
  rate: 'rate'
}
