// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const index = () => import('./views/index')

const registration = () => import('./views/registration')

const registrationSearch = () => import('./views/search')

const aboutTransportation = () => import('./views/about')

// start Routes
export default [
  {
    path: '/transportation',
    name: 'transportation',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'transportation',
      meta: {
        name: 'transportation',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'endUser.transport',
            active: true
          }
        ]
      },
      component: index
    }
    ]
  },
  {
    path: '/about-transportation',
    name: 'aboutTransportation',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'aboutTransportation',
      meta: {
        name: 'aboutTransportation',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'endUser.transport',
            active: true
          }
        ]
      },
      component: aboutTransportation
    }
    ]
  },
  {
    path: '/register-transportation',
    name: 'registerTransportation',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'registerTransportation',
      meta: {
        name: 'registerTransportation',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'endUser.transport',
            active: true
          }
        ]
      },
      component: registration
    }
    ]
  },
  {
    path: '/register-search',
    name: 'registerSearch',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'registerSearch',
      meta: {
        name: 'registerSearch',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'endUser.aboutUs',
            active: true
          }
        ]
      },
      component: registrationSearch
    }
    ]
  }
]
