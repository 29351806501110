// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const index = () => import('./views/index')
const videoDetails = () => import('./views/videoDetails')
// start Routes
export default [
  {
    path: '/videos',
    name: 'videos',
    component: websiteLayout,
    children: [
      {
        path: '',
        name: 'videos',
        meta: {
          name: 'videos',
          userType: '',
          permission: '',
          breadCrumb: [
            {
              text: 'endUser.videos',
              active: true
            }
          ]
        },
        component: index
      }, {
        path: ':videoId',
        name: 'videoDetails',
        meta: {
          name: 'videoDetails',
          userType: '',
          permission: '',
          breadCrumb: [
            {
              text: 'endUser.videos',
              active: true
            }
          ]
        },
        component: videoDetails
      }
    ]
  }
]
