// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const home = () => import('./views')

// start Routes
export default [
  {
    path: '/',
    name: 'home',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'home',
      meta: {
        name: 'home',
        userType: '',
        permission: '',
        breadCrumb: [
          /*   {
            text: 'endUser.aboutUs',
            active: true
          } */
        ]
      },
      component: home
    }
    ]
  }
]
