export default {
  discountCode: 'discount code',
  clientName: 'client name',
  country: 'country',
  city: 'city',
  street: 'street',
  district: 'district',
  phoneNumber: 'phone number',
  addressDetails: 'address in details',
  specialSign: 'special sign',
  cardNumber: 'card number',
  monthEnd: 'end month',
  yearEnd: 'year end',
  cvv: 'CVV',
  activate: 'activate',
  addNewCreditCard: 'add new credit card',
  addNewCredit: 'add credit card',
  sure: 'Done',
  paymentOptions: 'payment options',
  myAddress: 'My address',
  addNewAddress: 'add new address',
  addAddress: 'add address',
  chooseAddress: 'choose address',
  validOrder: 'confirm order',
  continueShopping: 'continue shopping',
  orderInfo: 'order details',
  tax: 'Tax',
  transportation: 'transportation',
  sum: 'sum',
  edit: 'edit',
  change: 'change'
}
