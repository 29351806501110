// Layout
const joiningLayout = () => import('@/layouts/joining')

const login = () => import('./views/loginProvider')
const registrationProvider = () => import('./views/registration')

// start Routes
export default [
  {
    path: '/join',
    name: 'join',
    component: joiningLayout,
    children: [
      {
        path: '',
        name: 'registrationProvider',
        meta: {
          name: 'registrationProvider',
          userType: '',
          permission: '',
          breadCrumb: [
          ]
        },
        component: registrationProvider
      },
      {
        path: 'login',
        name: 'loginProvider',
        meta: {
          name: 'loginProvider',
          userType: '',
          permission: '',
          breadCrumb: [
          ]
        },
        component: login
      }
    ]
  }
]
