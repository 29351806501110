// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const mediaCenter = () => import('./views/index')
const newDetails = () => import('@/modules/primaryPages/news/views/newDetails')
const eventsDetails = () => import('@/modules/servicePages/events/views/eventDetails')
// start Routes
export default [
  {
    path: '/media-center',
    name: 'mediaCenter',
    component: websiteLayout,
    children: [
      {
        path: '',
        name: 'mediaCenter',
        meta: {
          name: 'mediaCenter',
          userType: '',
          permission: '',
          breadCrumb: [
            {
              text: 'endUser.mediaCenter',
              active: true
            }
          ]
        },
        component: mediaCenter
      },
      {
        path: 'news/:newsID',
        name: 'newDetails',
        meta: {
          name: 'newDetails',
          userType: '',
          permission: '',
          breadCrumb: [
            {
              text: 'endUser.news',
              active: true
            }
          ]
        },
        component: newDetails
      },
      {
        path: 'events/:eventsId',
        name: 'eventsDetails',
        meta: {
          name: 'eventsDetails',
          userType: '',
          permission: '',
          breadCrumb: [
            {
              text: 'endUser.events',
              active: true
            }
          ]
        },
        component: eventsDetails
      }
    ]
  }
]
