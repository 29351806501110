export default {
  transportationDate: 'تاريخ النقل',
  transportationTime: 'وقت النقل',
  camelTransport: 'نقل الإبل',
  HowTransport: 'كيف تنقل؟',
  HowTransportDesc: 'من خلال منصة إبل، تستطيع إختيار الشاحنة التي ترغب في نقل إبلك بها و نوعها و حجمها، بالإضافة إلى التفاوض مع السائق لإختيار السعر المناسب لك لضمان أفضل وسائل نقل الإبل في أي وقت و في كل مكان داخل المملكة العربية السعودية',
  startingPoint: 'جهة الانطلاق',
  startLocation: 'مكان التحميل',
  endLocation: 'مكان التنزيل',
  destination: 'جهة الوصول ',
  tripDistance: 'مسافة الرحلة بالكيلومتر',
  headerTitle: 'قم بنقل الإبل وقت ما تشاء إلى أي مكان',
  headerSubtitle: 'الآن بإمكانك نقل الإبل الخاصة بك داخل المملكة',
  headerBtn: 'انقل الآن',
  headerDetails: 'تعتبر منصة إبل من المنصات المتخصصة و المتميزة في نقل الإبل بأمان وقت ما تشاء و إلى أي مكان نظرا لإيماننا بأهمية الإبل بالنسبة لتراثنا و تاريخنا السعودي العربي ولذلك وجب علينا إنشاء منصة تمكنك بسهولة من نقل الإبل. فنحن نحرص دائما على نقل الإبل بأقصى درجات الأمان و أفضل سعر ممكن.',
  transportDesc: 'بعد الانتهاء من تعبئة النموذج سيظهر لك سعر وثيقة نقل الإبل وفي حال قبولك للسعر يتم تحويلك لنظام الدفع من خلال احدى وسائل الدفع المعتمدة بالسعودية ،وبعدها تصدر فاتورة استلام الطلب ومن ثم وثيقة تأمين النقل.',
  chooseFreelyTitle: 'لك حرية الإختيار',
  chooseFreelyDesc: 'جميع ملاك الإبل في المملكة العربية السعودية لهم حرية إختيار الشاحنة و حجمها ، و السائق المناسب، بالسعر المناسب لهم',
  targetAudienceTitle: 'المستهدفين في النقل',
  targetAudienceDesc: 'جميع ملاك الإبل بمختلف أنواعها داخل المملكة العربية السعودية يمكنهم نقل الإبل بأمان داخل المملكة إلى أي مكان من خلال اتباع الخطوات الخاصة بنقل الإبل',
  choosePriceTitle: 'اختر السعر المناسب لك',
  choosePriceDesc: 'عند اختيار الشاحنة المناسبة ، تمتلك الحرية المطلقة في تحديد السعر المناسب لك عن طريق التفاوض مع السائق الذي قمت باختياره لنقل الإبل الخاصة بك',
  vehicleType: 'نوع السيارة',
  vehicleYear: 'سنة الصنع',
  vehicleNumber: 'رقم السيارة',
  acceptTrip: 'تم قبول رحلتك من السائق',
  vehicleInfo: 'بيانات السيارة',
  payWithin: 'قم بالدفع خلال 15 دقيقة حتى يتم تأكيد الطلب',
  attention: 'تنبيه',
  amountPaid: 'المبلغ المطلوب دفعه',
  approximationPrice: 'المبلغ التقريبي',
  paymentCompleted: 'تم الدفع بنجاح',
  thanks: 'شكراً لك ،',
  downloadApp: 'قم بتحميل البرنامج الخاص بنا من جوجل بلاى او اب ستور لتتبع رحلاتك والاستمتاع بكل خدماتك',
  shipmentData: 'بيانات الشحنة',
  applicant: 'مقدم الطلب',
  totalValueTransport: 'إجمالي قيمة الرحلة',
  trip: 'الرحلة',
  trips: 'الرحلات',
  cancel: 'إلغاء',
  youSureCancel: 'هل أنت متأكد أنك تريد إلغاء الرحلة؟',
  deliveryData: 'بيانات التوصيل',
  chooseVehicleType: 'اختيار نوع الشحنة',
  payment: 'الدفع',
  camel: 'متن',
  approximatePrice: 'السعر التقريبى',
  selectTruck: 'يجب أختيار نوع شاحنة أولا',
  lookingDrivers: 'جارى البحث عن سائقين لرحلتك',
  notifyDriver: 'سيتم اعلامك فور قبول سائق لرحلتك',
  driverName: 'اسم السائق',
  companyName: 'اسم الشركة',
  driverOffer: 'عرض السائق',
  companyOffer: 'عرض الشركة',
  cancelReason: 'سبب الالغاء ',
  tripCancelIn: 'تم إلغاء الرحلة في',
  vehicleData: 'بيانات الشاحنة',
  transferData: 'بيانات النقل',
  name: 'الاسم:',
  send: 'ارسال',
  accept: 'قبول',
  refuse: 'رفض',
  refuseTrip: 'الغاء الرحلة',
  continue: 'التالى',
  from: 'من',
  to: 'إلى',
  tripId: 'رقم الرحلة',
  OrderNumber: 'رقم الطلب',
  customerData: 'بيانات العميل',
  distance: 'المسافة',
  time: 'الساعة',
  camelNumber: 'عدد الأيل',
  DriverPhoto: 'صوره السائق',
  numberOfTrips: 'عدد الرحلات التى قام بها',
  driverRating: 'تقييم السائق',
  rs: 'رس',
  comment: 'التعليق',
  addComment: 'اضافة تعليق',
  rate: 'التقييم'
}
