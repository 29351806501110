// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const index = () => import('./views/index')

const registration = () => import('./views/registration')

const aboutVeterinarian = () => import('./views/about')

// start Routes
export default [
  {
    path: '/veterinarian',
    name: 'veterinarian',
    component: websiteLayout,
    children: [
      {
        path: '',
        name: 'veterinarian',
        meta: {
          name: 'veterinarian',
          userType: '',
          permission: '',
          breadCrumb: [
            {
              text: 'endUser.veterinarian',
              active: true
            }
          ]
        },
        component: index
      }
    ]
  },
  {
    path: '/about-veterinarian',
    name: 'aboutVeterinarian',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'aboutVeterinarian',
      meta: {
        name: 'aboutVeterinarian',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'endUser.veterinarian',
            active: true
          }
        ]
      },
      component: aboutVeterinarian
    }
    ]
  },
  {
    path: '/register-veterinarian',
    name: 'registerVeterinarian',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'registerVeterinarian',
      meta: {
        name: 'registerVeterinarian',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'endUser.veterinarian',
            active: true
          }
        ]
      },
      component: registration
    }
    ]
  }
]
