// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const index = () => import('./views/index')
const marketPlaceIndex = () => import('./views/allStores/index')
const allMarketplaces = () => import('./views/allStores/allMarketplaces')
const storeProfile = () => import('./views/storeProfile/index')
const productsCategories = () => import('./views/storeProfile/productsCategories')
const aboutStore = () => import('./views/storeProfile/aboutStore')
const privacyPolicyStore = () => import('./views/storeProfile/privaceAndPolicies')
const productDetails = () => import('./views/productDetails')
const cart = () => import('./views/marketplaceCart/cart')
const paymentActions = () => import('./views/marketplaceCart/paymentActions')
// start Routes
export default [
  {
    path: '/stores',
    name: 'stores',
    component: websiteLayout,
    children: [
      {
        path: '',
        name: 'stores',
        meta: {
          name: 'stores',
          userType: '',
          permission: '',
          breadCrumb: [
            {
              text: 'marketplace.categories',
              active: true
            }
          ]
        },
        component: index
      },
      {
        path: 'merchants/:id',
        name: 'allMarketplaces',
        component: marketPlaceIndex,
        children: [
          {
            path: '',
            name: 'allMarketplaces',
            component: allMarketplaces,
            meta: {
              name: 'allMarketplaces',
              userType: '',
              permission: '',
              breadCrumb: [
                {
                  text: 'marketplace.categories',
                  to: 'stores'
                },
                {
                  text: 'marketplace.stores',
                  active: true
                }
              ]
            }
          },
          {
            path: 'store-profile/:slogan',
            name: 'storeProfile',
            component: storeProfile,
            breadCrumb: [],
            meta: {
              name: 'allMarketplaces',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            children: [
              {
                path: '',
                name: 'storeProfile',
                component: productsCategories,
                breadCrumb: [],
                meta: {
                  name: 'allMarketplaces',
                  userType: '',
                  permission: '',
                  breadCrumb: [
                    // {
                    //   text: 'marketplace.categories',
                    //   to: 'stores'
                    // },
                    // {
                    //   text: 'marketplace.stores',
                    //   to: 'index'
                    // },
                    // {
                    //   text: 'marketplace.products',
                    //   active: true
                    // }
                  ]
                }
              },
              {
                path: 'products/:productId',
                name: 'productDetails',
                props: true,
                component: productDetails,
                breadCrumb: [],
                meta: {
                  name: 'productDetails',
                  userType: '',
                  permission: '',
                  breadCrumb: [
                    // {
                    //   text: 'marketplace.stores',
                    //   to: 'index'
                    // },
                    // {
                    //   text: 'marketplace.stores',
                    //   active: true
                    // }
                  ]
                }
              },
              {
                path: 'about-store',
                name: 'aboutStore',
                component: aboutStore,
                breadCrumb: [],
                meta: {
                  name: 'aboutStore',
                  userType: '',
                  permission: '',
                  breadCrumb: [
                    // {
                    //   text: 'marketplace.stores',
                    //   to: 'index'
                    // },
                    // {
                    //   text: 'marketplace.stores',
                    //   active: true
                    // }
                  ]
                }
              },
              {
                path: 'privacy-store',
                name: 'privacyPolicyStore',
                component: privacyPolicyStore,
                breadCrumb: [],
                meta: {
                  name: 'privacyPolicyStore',
                  userType: '',
                  permission: '',
                  breadCrumb: [
                    // {
                    //   text: 'marketplace.stores',
                    //   to: 'index'
                    // },
                    // {
                    //   text: 'marketplace.stores',
                    //   active: true
                    // }
                  ]
                }
              }

            ]
          }
        ]
      },
      {
        path: '/products-categories',
        name: 'productsCategories',
        component: websiteLayout,
        children: [{
          path: '',
          name: 'productsCategories',
          meta: {
            breadCrumb: [],
            name: 'productsCategories',
            userType: '',
            permission: ''
          },
          breadCrumb: [],
          component: productsCategories
        }
        ]
      }
    ]
  },
  {
    path: '/cart',
    name: 'cart',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'cart',
      meta: {
        name: 'cart',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'cart.cart',
            active: true
          }
        ]
      },
      component: cart
    },
    {
      path: 'payment-actions/:id',
      name: 'payment-actions',
      props: true,
      component: paymentActions
      /*      children: [{
        path: '',
        name: 'paymentActions',
        meta: {
          name: 'paymentActions',
          userType: '',
          permission: ''
        },
        component: cart
      }
      ] */
    }
    ]
  }
]
