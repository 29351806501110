export default {
  english: 'الأنجليزية',
  arabic: 'العربية',
  login: 'تسجيل الدخول',
  cart: 'سلة المشتريات',
  filter: 'فلاتر',
  search: 'بحث',
  country: 'الدولة',
  city: 'المدينة',
  cities: 'مدن',
  sort: 'البحث الافتراضى',
  products: 'المنتجات',
  product: 'منتج',
  getDefault: 'الفرز الافتراضى',
  categories: 'الاقسام',
  searchResult: 'نتائج البحث',
  noResult: 'لا توجد نتائج بحث',
  marketplace: 'المتجر',
  website: 'الويب سايت',
  insurance: 'التامين',
  transportation: 'النقل',
  veterinary: 'البيطرة'
}
