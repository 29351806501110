export const VUE_APP_MARKETPLACE_LINK = process.env.VUE_APP_MARKETPLACE_LINK || 'https://api-marketplace.ibbil.com/api/v1/'
export const VUE_APP_SSO_LINK = process.env.VUE_APP_SSO_LINK || 'https://auth.ibbil.com/'
export const VUE_APP_INSURANCE_LINK = process.env.VUE_APP_INSURANCE_LINK || 'https://api-insurance.ibbil.com/'
export const VUE_APP_TRANSPORTATION_LINK = process.env.VUE_APP_TRANSPORTATION_LINK || 'https://api-transportation.ibbil.com/api/'
export const VUE_APP_WEBSITE_LINK = process.env.VUE_APP_WEBSITE_LINK || 'https://api-web.ibbil.com/'
export const VUE_APP_VETERINARY_LINK = process.env.VUE_APP_VETERINARY_LINK || 'https://api-veterinary.ibbil.com/api/v1/'
export const VUE_APP_FRONT_MARKETPLACE_LINK = process.env.VUE_APP_FRONT_MARKETPLACE_LINK || 'https://dashboard-marketplace.ibbil.com'
export const VUE_APP_FRONT_INSURANCE_LINK = process.env.VUE_APP_FRONT_INSURANCE_LINK || 'https://insurance-dashboard.ibbil.com/'
export const VUE_APP_FRONT_DRIVER_TRANSPORTATION_LINK = process.env.VUE_APP_FRONT_DRIVER_TRANSPORTATION_LINK || 'https://transportaion-dashboard.ibbil.com'
export const VUE_APP_FRONT_COMPANY_TRANSPORTATION_LINK = process.env.VUE_APP_FRONT_COMPANY_TRANSPORTATION_LINK || 'https://transportaion-dashboard.ibbil.com'
export const VUE_APP_FRONT_DOCTOR_VETERINARY_LINK = process.env.VUE_APP_FRONT_DOCTOR_VETERINARY_LINK || 'https://dashboard-veterinary.ibbil.com'
export const VUE_APP_FRONT_CLINIC_VETERINARY_LINK = process.env.VUE_APP_FRONT_CLINIC_VETERINARY_LINK || 'https://dashboard-veterinary.ibbil.com'
export const VUE_APP_VETERINARY_DASHBOARD_LINK = process.env.VUE_APP_VETERINARY_DASHBOARD_LINK || 'https://dashboard-veterinary.ibbil.com'
export const FIREBASE_API_KEY = process.env.VUE_FIREBASE_API_KEY || 'AIzaSyCOjq1ZfAuB3sF9bA5M7i3fbKA0oJxTSBg'
export const FIREBASE_AUTH_DOMAIN = process.env.VUE_FIREBASE_AUTH_DOMAIN || 'ibbil-6e726.firebaseapp.com'
export const FIREBASE_PROJECT_ID = process.env.VUE_FIREBASE_PROJECT_ID || 'ibbil-6e726'
export const FIREBASE_STORAGE_BUCKET = process.env.VUE_FIREBASE_STORAGE_BUCKET || 'ibbil-6e726.appspot.com'
export const FIREBASE_MESSAGING_SENDER_ID = process.env.VUE_FIREBASE_MESSAGING_SENDER_ID || '846950817960'
export const FIREBASE_APP_ID = process.env.VUE_FIREBASE_APP_ID || '1:846950817960:web:d4acbd5056630d8c09a29d'
export const FIREBASE_MEASUREMENT_ID = process.env.VUE_FIREBASE_MEASUREMENT_ID || 'G-MCBGKVMLEL'
