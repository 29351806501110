import { core } from '@/config/pluginInit'

export default {
  async addToCart ({ state, commit }, payload) {
    // from product pge
    if (payload.quantityToBuy) {
      const ifStoreInCart = state.cart.findIndex(store => {
        return store.store_info.id === payload.store_id
      })
      if (ifStoreInCart > -1) {
        state.cart[ifStoreInCart].store_products.push({ ...payload, quantityInCart: payload.quantityToBuy, totalProductPrice: payload.price_info.final_price * payload.quantityToBuy })
        commit('calculateStoreFees', ifStoreInCart)
        commit('calculateStoreFeesWithoutTax', ifStoreInCart)
      } else {
        state.cart.push({
          store_info: {
            name: state.selectedStore.name,
            image: state.selectedStore.logo,
            id: payload.store_id,
            category_id: state.selectedStore.category_id,
            url: state.selectedStore.url,
            totalFees: payload.price_info.final_price * payload.quantityToBuy
          },
          store_products: [{ ...payload, quantityInCart: payload.quantityToBuy, totalProductPrice: payload.price_info.final_price * payload.quantityToBuy }]
        })
        commit('calculateStoreFeesWithoutTax', state.cart.length - 1)
      }
      commit('changeCartInLocalStorage')
    } else {
      const ifStoreInCart = state.cart.findIndex(store => {
        return store.store_info.id === payload.store_id
      })
      if (ifStoreInCart > -1) {
        state.cart[ifStoreInCart].store_products.push({ ...payload, quantityInCart: 1, totalProductPrice: payload.price_info.final_price })
        commit('calculateStoreFees', ifStoreInCart)
        commit('calculateStoreFeesWithoutTax', ifStoreInCart)
      } else {
        state.cart.push({
          store_info: {
            name: state.selectedStore.name,
            image: state.selectedStore.logo,
            id: payload.store_id,
            category_id: state.selectedStore.category_id,
            url: state.selectedStore.url,
            totalFees: payload.totalWithTax
          },
          store_products: [{ ...payload, quantityInCart: 1, totalProductPrice: payload.price_info.final_price }]
        })
        commit('changeCartInLocalStorage')
      }
      commit('calculateStoreFeesWithoutTax', ifStoreInCart)
      commit('changeCartInLocalStorage')
    }
  },
  updateCart ({ state, commit }, payload) {
    state.cart = payload
  },
  increaseQuantity ({ state, commit }, payload) {
    console.log(payload)
    const findIndexOfStore = state.cart.findIndex(storeData => {
      return storeData.store_info.id === payload.store_id
    })
    if (findIndexOfStore > -1) {
      const findIndexOdProduct = state.cart[findIndexOfStore].store_products.findIndex(product => {
        return product.id === payload.id
      })
      if (state.cart[findIndexOfStore].store_products[findIndexOdProduct].quantity === state.cart[findIndexOfStore].store_products[findIndexOdProduct].quantityInCart) {
      } else {
        state.cart[findIndexOfStore].store_products[findIndexOdProduct].quantityInCart++
        state.cart[findIndexOfStore].store_products[findIndexOdProduct].totalProductPrice = state.cart[findIndexOfStore].store_products[findIndexOdProduct].quantityInCart * state.cart[findIndexOfStore].store_products[findIndexOdProduct].price_info.final_price
        commit('calculateStoreFees', findIndexOfStore)
        commit('calculateStoreFeesWithoutTax', findIndexOfStore)
        commit('changeCartInLocalStorage')
      }
    }
  },
  decreaseQuantity ({ state, commit }, payload) {
    const findIndexOfStore = state.cart.findIndex(storeData => {
      return storeData.store_info.id === payload.store_id
    })
    if (findIndexOfStore > -1) {
      const findIndexOdProduct = state.cart[findIndexOfStore].store_products.findIndex(product => {
        return product.id === payload.id
      })
      if (state.cart[findIndexOfStore].store_products[findIndexOdProduct].quantityInCart === 1) {
        state.cart[findIndexOfStore].store_products[findIndexOdProduct].totalProductPrice = state.cart[findIndexOfStore].store_products[findIndexOdProduct].quantityInCart * state.cart[findIndexOfStore].store_products[findIndexOdProduct].price_info.final_price
      } else {
        state.cart[findIndexOfStore].store_products[findIndexOdProduct].quantityInCart--
        state.cart[findIndexOfStore].store_products[findIndexOdProduct].totalProductPrice = state.cart[findIndexOfStore].store_products[findIndexOdProduct].quantityInCart * state.cart[findIndexOfStore].store_products[findIndexOdProduct].price_info.final_price
        commit('calculateStoreFees', findIndexOfStore)
        commit('calculateStoreFeesWithoutTax', findIndexOfStore)
        commit('changeCartInLocalStorage')
      }
    }
  },
  deleteProductInCart ({ state, commit }, payload) {
    const findIndexOfStore = state.cart.findIndex(storeData => {
      return storeData.store_info.id === payload.store_id
    })
    if (findIndexOfStore > -1) {
      if (state.cart[findIndexOfStore].store_products.length === 1) {
        commit('deleteAllStoreInCart', state.cart[findIndexOfStore])
      } else {
        const findIndexOdProduct = state.cart[findIndexOfStore].store_products.findIndex(product => {
          return product.id === payload.id
        })
        state.cart[findIndexOfStore].store_products.splice(findIndexOdProduct, 1)
        commit('calculateStoreFees', findIndexOfStore)
        commit('calculateStoreFeesWithoutTax', findIndexOfStore)
        commit('changeCartInLocalStorage')
      }
    }
  },
  setFavourite ({ state, commit }, payload) {
    state.favourite = payload
  },
  addToFavourite ({ state, commit }, payload) {
    const ifProdInFav = state.favourite.findIndex(product => {
      return product.id === payload.id
    })
    if (ifProdInFav > -1) {
      console.log('remove')
    } else {
      state.favourite.push(payload)
      commit('changeFavouriteInLocalStorage')
      core.showSnackbar('success', 'تم اضافة المنتج فى المفضلة')
    }
  },
  deleteProductInFavourite ({ state, commit }, payload) {
    const ifProdInFav = state.favourite.findIndex(product => {
      return product.id === payload.id
    })
    if (ifProdInFav > -1) {
      state.favourite.splice(ifProdInFav, 1)
      commit('changeFavouriteInLocalStorage')
      core.showSnackbar('success', 'تم ازالة المنتج من المفضلة')
    }
  }
}
