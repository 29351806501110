// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const index = () => import('./views/index')

// start Routes
export default [
  {
    path: '/permissions',
    name: 'permissions',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'permissions',
      meta: {
        name: 'permissions',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'endUser.permissions',
            active: true
          }
        ]
      },
      component: index
    }
    ]
  }
]
