export default {
  home: 'home',
  statistics: 'statistics',
  Permissions: 'Permissions',
  insurance: 'insurance',
  transportation: 'transportation',
  veterinary: 'veterinary',
  events: 'Events',
  marketplace: 'marketplace',
  wallet: 'E-wallet',
  favourite: 'favourite',
  comments: 'my comments',
  settings: 'settings',
  insuranceDetails: 'insurance details',
  insuranceNow: 'insurance now',
  transportationNow: 'request trip',
  transportationDetails: 'transportation details',
  shopping: 'shopping',
  orders: 'orders',
  searchHere: 'search here',
  company: 'company',
  status: 'status',
  lastEnter: 'last enter date',
  documented: 'documented',
  veterinaryDetails: 'Veterinary Details',
  emptyFavourite: 'You don\'t have a favorite yet',
  goHomepage: 'Go To Home Page'
}
