export default {
  IdNumber: 'رقم الهوية',
  IdNumberWithCondition: 'رقم الهوية يبدأ 1 او 2',
  password: 'الرقم السرى',
  newPassword: 'الرقم السرى الجديد',
  passwordChecked: 'تأكيد الرقم السرى',
  currentPassword: 'الرقم السرى الحالى',
  changePassword: 'تغير الرقم السرى',
  phoneNumber: 'رقم الجوال',
  countryCode: 'رمز الدولة',
  userName: 'اسم المستخدم',
  fullName: 'الاسم بالكامل',
  email: 'البريد الالكترونى',
  address: 'العنوان',
  otbCode: 'الرمز',
  check: 'تحقق',
  enter: 'دخول',
  enterAccount: 'تسجيل الدخول ؟',
  createNewAccount: ' تسجيل حساب جديد ؟',
  createAccount: ' تسجيل حساب',
  codeOtb: 'كود الرمز',
  otbTitle: 'تم إرسال رسالة إلى رقم الجوال الخاص بكم',
  otbInfo: 'تتضمن رمز OTB',
  continueOtb: 'المتابعة',
  create: 'تسجيل ',
  logIn: 'تسجيل دخول ',
  logout: 'تسجيل خروج',
  forgetPassword: 'نسيت كلمة السر ؟',
  send: 'ارسال',
  connectWith: 'تواصل معنا',
  messageText: 'نص الرسالة"',
  aboutUs: 'معلومات عن',
  save: 'حفظ',
  userInformation: 'بينات المستخدم',
  change: 'تعديل',
  resetPassword: 'ضبط كلمه السر',
  resetPasswordContent: 'لنسيان كلمة المرور ، سنرسل لك رمزًا إلى رقم هاتفك لإعادة تعيين كلمة المرور',
  secondary_phone_number: 'رقم هاتف اخر'
}
