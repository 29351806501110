// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const index = () => import('./views/index')
const newDetails = () => import('./views/newDetails')
// start Routes
export default [
  {
    path: '/news',
    name: 'news',
    component: websiteLayout,
    children: [
      {
        path: '',
        name: 'news',
        meta: {
          name: 'news',
          userType: '',
          permission: '',
          breadCrumb: [
            {
              text: 'endUser.news',
              active: true
            }
          ]
        },
        component: index
      }, {
        path: ':newsID',
        name: 'newDetails',
        meta: {
          name: 'newDetails',
          userType: '',
          permission: '',
          breadCrumb: [
            {
              text: 'endUser.news',
              active: true
            }
          ]
        },
        component: newDetails
      }
    ]
  }
]
