import Vue from 'vue'
import Vuex from 'vuex'
import Setting from './Setting/index'
import cart from './cart'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Setting,
    cart
  },
  state: {
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user : null
  },
  mutations: {
    updateUserInfo (state, data) {
      state.userInfo = data
    }
  },
  actions: {
    setUserInfo (context, payload) {
      context.commit('updateUserInfo', payload)
    }
  },
  getters: {
    getUserInfo: state => state.userInfo
  },
  strict: debug
})
