export default {
  ibbilMarketPlaces: 'Ibbil Marketplace',
  stores: 'stores',
  products: 'products',
  aboutStores: 'about stores',
  aboutStore: 'about store',
  allStores: 'all stores',
  camelStores: 'camel stores',
  feedStores: 'feed stores',
  LatestStores: 'Latest stores',
  camelProductsStores: 'camel products stores',
  animalFoodStores: 'animal food stores',
  camelSuppliesStores: 'camel supplies stores',
  electronicChipStores: 'electronic chip stores',
  driveTo: 'delivery to',
  TermsAndConditions: 'Terms and Conditions',
  seeMore: 'see more',
  addToCart: 'add to cart',
  inCart: 'in cart',
  addToFavourite: 'add to favourite',
  removeFromFavourite: 'remove from favourite',
  backToProfile: 'back',
  quantity: 'quantity',
  savedPrice: 'saved',
  beforePrice: 'before',
  discount: 'discount',
  discountCode: 'discount code',
  price: 'price',
  priceNow: 'price now',
  total: 'total',
  Specifications: 'Specifications',
  clientsComments: 'clients comments',
  seeAll: 'see all',
  mayYouLike: 'May You Like',
  all: 'all',
  validProductRate: 'valid Product Rate',
  userProductComments: 'clients product comments',
  validProductPayment: 'valid product payment',
  commentedAt: 'commented at',
  saveToFavourite: 'save to favourite',
  backToStore: ' back to Store',
  continuePayment: 'continue payment',
  mainQuestions: 'main questions',
  marketplaceInfo: 'The IBBIL platform offers an integrated commercial market place to provide all services related to camels in the Kingdom of Saudi Arabia, including food and camel supplies, where a large and diverse group of stores for various needs of camels are available with the highest quality and best prices. Through Merchants and shop owners who offer their services and products on the IBBIL platform, you can choose your preferred store to shop from for your camel.',
  orderNumber: 'order number',
  orderDate: 'order date',
  totalCostOfOrder: 'total cost of order',
  rs: 'SAR',
  storeName: 'store name',
  productName: 'product name',
  showProduct: 'show product',
  printReceipt: 'print the receipt'
}
