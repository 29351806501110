export default {
  english: 'English',
  arabic: 'Arabic',
  login: 'Login',
  cart: 'Cart',
  filter: 'filter',
  search: 'search',
  country: 'country',
  city: 'city',
  cities: 'cities',
  sort: 'sort',
  categories: 'categories',
  products: 'products',
  product: 'product',
  getDefault: 'filter',
  searchResult: 'search results',
  noResult: 'no results found',
  marketplace: 'marketplace',
  website: 'website',
  insurance: 'insurance',
  transportation: 'transportation',
  veterinary: 'veterinary'
}
