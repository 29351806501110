// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const index = () => import('./views/index')
const eventsDetails = () => import('./views/eventDetails')

// start Routes
export default [
  {
    path: '/events',
    name: 'events',
    component: websiteLayout,
    children: [
      {
        path: '',
        name: 'events',
        meta: {
          name: 'events',
          userType: '',
          permission: '',
          breadCrumb: [
            {
              text: 'endUser.events',
              active: true
            }
          ]
        },
        component: index
      },
      {
        path: ':eventsId',
        name: 'eventsDetails',
        meta: {
          name: 'eventsDetails',
          userType: '',
          permission: '',
          breadCrumb: [
            {
              text: 'endUser.events',
              active: true
            }
          ]
        },
        component: eventsDetails
      }
    ]
  }
]
