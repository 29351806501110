// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const index = () => import('./views/index')
const about = () => import('./views/about')
const registration = () => import('./views/registration')

// start Routes
export default [
  {
    path: '/insurance',
    name: 'insurance',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'insurance',
      meta: {
        name: 'insurance',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'endUser.insurance',
            active: true
          }
        ]
      },
      component: index
    }
    ]
  },
  {
    path: '/about-insurance',
    name: 'aboutInsurance',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'aboutInsurance',
      meta: {
        name: 'aboutInsurance',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'endUser.insurance',
            active: true
          }
        ]
      },
      component: about
    }
    ]
  },
  {
    path: '/register-insurance',
    name: 'registerInsurance',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'registerInsurance',
      meta: {
        name: 'registerInsurance',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'endUser.insurance',
            active: true
          }
        ]
      },
      component: registration
    }
    ]
  }
]
