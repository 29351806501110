import { singleSignOn } from '@/axios'

export default {
  login (payload) {
    return singleSignOn().post('sso-login', payload)
  },
  forgetPassword (payload) {
    return singleSignOn().post('sso-forget-password', payload)
  },
  checkUser (paylod) {
    return singleSignOn().post('sso-check', paylod)
  },
  register (payload) {
    return singleSignOn().post('sso-signup', payload)
  },
  sendOtp (payload) {
    return singleSignOn().post('sso-send-otp', payload)
  },
  checkOtp (payload) {
    return singleSignOn().post('sso-verify-otp', payload)
  },
  logout () {
    return singleSignOn().post('sso-logout')
  },
  sendFirebase (payload) {
    return singleSignOn().patch('v1/fcm-tokens', { fcm_token: payload })
  }
}
