export default {
  data () {
    return {
      transelateStatus: {
        pending: 'جارى الطلب',
        refused: 'تم الرفض',
        on_delivery: 'جارى الشحن',
        success: 'تم الاستلام'
      }
    }
  }
}
