// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const userProfile = () => import('./views/index')
const home = () => import('./views/home')
const statistics = () => import('./views/statistics')
const favourite = () => import('./views/favourite')
const insurance = () => import('./views/insurance')
const userTransportation = () => import('./views/userTransportation')
const userVeterinary = () => import('./views/userVeterinary')
const userMarketplace = () => import('./views/userMarketplace')
const indexWallet = () => import('./views/wallet/index')
const wallet = () => import('./views/wallet/wallet')
const walletSetings = () => import('./views/wallet/settings')
const commingSoon = () => import('@/views/Pages/ComingSoon')
const userSettings = () => import('@/modules/userProfile/views/userSettings')
// start Routes
export default [
  {
    path: '/user-profile',
    name: 'user-profile',
    component: websiteLayout,
    children: [
      {
        path: '',
        name: 'userProfile',
        meta: {
          name: 'userProfile',
          userType: '',
          permission: '',
          breadCrumb: [
          ]
        },
        component: userProfile,
        children: [
          {
            path: 'user-home',
            name: 'user-home',
            meta: {
              name: 'home',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: home
          },
          {
            path: 'statistics',
            name: 'statistics',
            meta: {
              name: 'statistics',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: statistics
          },
          {
            path: 'user-permissions',
            name: 'user-permissions',
            meta: {
              name: 'user-permissions',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: commingSoon
          },
          {
            path: 'user-veterinary',
            name: 'user-veterinary',
            meta: {
              name: 'user-veterinary',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: userVeterinary
          },
          {
            path: 'user-events',
            name: 'user-events',
            meta: {
              name: 'user-events',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: commingSoon
          },
          {
            path: 'user-comments',
            name: 'user-comments',
            meta: {
              name: 'user-comments',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: commingSoon
          },
          {
            path: 'favourite',
            name: 'favourite',
            meta: {
              name: 'favourite',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: favourite
          },
          {
            path: 'insurance-profile',
            name: 'insurance-profile',
            meta: {
              name: 'insurance-profile',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: insurance
          },
          {
            path: 'transportation-profile',
            name: 'transportation-profile',
            meta: {
              name: 'transportation-profile',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: userTransportation
          },
          {
            path: 'user-marketplace',
            name: 'user-marketplace',
            meta: {
              name: 'user-marketplace',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: userMarketplace
          },
          {
            path: 'user-settings',
            name: 'userSettings',
            meta: {
              name: 'user-settings',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: userSettings
          },
          {
            path: 'wallet',
            name: 'indexWallet',
            component: indexWallet,
            children: [
              {
                path: '',
                name: 'indexWallet',
                meta: {
                  name: 'indexWallet',
                  userType: '',
                  permission: '',
                  breadCrumb: []
                },
                component: wallet
              },
              {
                path: 'wallet-setings',
                name: 'walletSetings',
                meta: {
                  name: 'walletSetings',
                  userType: '',
                  permission: '',
                  breadCrumb: []
                },
                component: walletSetings
              }
            ]
          }
        ]
      }
    ]
  }
]
