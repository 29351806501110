import authRoutes from './auth/auth.routes'
import joinRoutes from './joining/joining.routes'

// primary Pages
import homeRoutes from './primaryPages/home/home.routes'
import aboutUsRoutes from './primaryPages/aboutUs/aboutUs.routes'
import termsConditionRoutes from './primaryPages/termsCondition/termsCondition.routes'
import privacyPolicyRoutes from './primaryPages/privacyPolicy/privacyPolicy.routes'
import servicesRoutes from './primaryPages/services/services.routes'
import mediaCenter from './primaryPages/mediaCenter/mediaCenter.routes'
import contactUsRoutes from './primaryPages/contactUs/contactUs.routes'
import userProfile from './userProfile/userProfile.routes'
import imagesRoutes from '@/modules/primaryPages/images/images.routes'
import videoRoutes from '@/modules/primaryPages/videos/videos.routes'
import allNews from '@/modules/primaryPages/news/news.routes'
// service Pages
import insurance from './servicePages/insurance/insurance.routes'
import marketplace from './servicePages/marketplace/marketplace.routes'
import permissions from './servicePages/permissions/permissions.routes'
import transportation from './servicePages/transportation/transportation.routes'
import events from './servicePages/events/events.routes'
import camelTrade from './servicePages/camelTrade/camelTrade.routes'
import veterinarian from './servicePages/veterinarian/veterinarian.routes'
// search
import search from './search/search.routes'

export default [
  ...authRoutes,
  ...joinRoutes,
  ...homeRoutes,
  ...allNews,
  ...userProfile,
  ...aboutUsRoutes,
  ...termsConditionRoutes,
  ...privacyPolicyRoutes,
  ...imagesRoutes,
  ...videoRoutes,
  ...servicesRoutes,
  ...mediaCenter,
  ...contactUsRoutes,
  ...insurance,
  ...marketplace,
  ...permissions,
  ...transportation,
  ...events,
  ...camelTrade,
  ...veterinarian,
  ...search
]
