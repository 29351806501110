// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const images = () => import('./views/index')
const imageDetails = () => import('./views/imageDetails')
// start Routes
export default [
  {
    path: '/images',
    name: 'images',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'images',
      meta: {
        name: 'images',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'endUser.images',
            active: true
          }
        ]
      },
      component: images
    },
    {
      path: ':imageId',
      name: 'imageDetails',
      meta: {
        name: 'imageDetails',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'endUser.images',
            active: true
          }
        ]
      },
      component: imageDetails
    }
    ]
  }
]
